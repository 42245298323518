<template>
  <div class="nofall">
    <div class="towtable">
      <div>
        <div class="_SelectHeader" style="justify-content: space-between">
          <span>不符合项记录</span>
          <i
            class="el-icon-circle-plus-outline"
            @click="operation(true)"
            style="font-size: 22px"
          ></i>
        </div>
        <!-- @row-click="rowClick" -->
        <el-table
          :data="tableList"
          :height="elementHeight"
          style="width: 100%"
          id="table"
          :row-class-name="tableRowClassName"
          :row-style="selectedstyle"
        >
          <el-table-column prop="fallName" label="不符合项名称">
          </el-table-column>
          <el-table-column prop="fallremark" label="不符合备注">
          </el-table-column>
          <el-table-column prop="decide" label="凭证"> </el-table-column>
          <el-table-column prop="isUse" label="是否启用" width="80">
            <template slot-scope="scope">
              <span>{{ scope.row.isUse == 1 ? "否" : "是" }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="" label="操作" width="150">
            <template slot-scope="scope">
              <i
                class="el-icon-edit i-popover"
                style="color: #8ac3f5; cursor: pointer"
                @click="operation(false, scope.row)"
              >
                修改</i
              >
              <i
                class="el-icon-delete i-popover"
                style="color: red; padding-left: 10px; cursor: pointer"
                @click="deleteItem(scope.row)"
              >
                删除</i
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div>
        <div class="_SelectHeader" style="justify-content: space-between">
          <span>不符合项用户</span>
          <i
            class="el-icon-circle-plus-outline"
            @click="operationUser(true)"
            style="font-size: 22px"
          ></i>
        </div>
        <!-- @row-click="rowClickUser" -->
        <el-table
          :data="tableListUser"
          :height="elementHeight"
          style="width: 100%"
          id="table"
          :row-class-name="tableRowClassName"
          :row-style="selectedstyle"
        >
          <el-table-column prop="fallName" label="不符合项" width="200">
          </el-table-column>
          <el-table-column prop="isUse" label="是否启用"> </el-table-column>
          <el-table-column prop="" label="操作">
            <template slot-scope="scope">
              <i
                class="el-icon-edit i-popover"
                style="color: #8ac3f5; cursor: pointer"
                @click="operationUser(false, scope.row)"
              >
                修改</i
              >
              <i
                class="el-icon-delete i-popover"
                style="color: red; padding-left: 10px; cursor: pointer"
                @click="deleteItemUser(scope.row)"
              >
                删除</i
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <Dialog
      :title="isAdd ? '新增不符合项' : '修改不符合项'"
      @handleClose="handleClose"
      width="25%"
      top="15vh"
      :showTowText="true"
      @handleReqData="handleReqData"
      :showDialog="showDialog"
    >
      <div class="nofaildialog">
        <div style="margin-bottom: 15px">
          <span>不符合项名称</span>
          <el-input placeholder="请输入不符合项名称" v-model="fallName">
          </el-input>
        </div>
        <div style="margin-bottom: 15px">
          <span>凭证</span>
          <el-input type="textarea" placeholder="请输入凭证" v-model="decide">
          </el-input>
        </div>
        <div style="margin-bottom: 15px">
          <span>不符合项备注</span>
          <el-input
            type="textarea"
            placeholder="请输入不符合项备注"
            v-model="fallremark"
          >
          </el-input>
        </div>
        <div style="text-align: end">
          <el-radio v-model="isUse" label="1">不启用</el-radio>
          <el-radio v-model="isUse" label="0">启用</el-radio>
        </div>
      </div>
    </Dialog>
    <Dialog
      :title="isAddUser ? '新增不符合项用户' : '修改不符合项用户'"
      @handleClose="handleCloseUser"
      width="25%"
      top="15vh"
      :showTowText="true"
      @handleReqData="handleReqDataUser"
      :showDialog="showDialogUser"
    >
      <div class="nofaildialog" style="text-align: end; padding-right: 40px">
        <div>
          <span>不符合项：</span>
          <el-select v-model="falid" clearable placeholder="请选择">
            <el-option
              v-for="item in tableList"
              :key="item.id"
              :label="item.fallName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div style="margin-top: 10px">
          <el-radio v-model="isUseUser" label="1">不启用</el-radio>
          <el-radio v-model="isUseUser" label="0">启用</el-radio>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { get, post, put, deleteMethod } from "../../api/http";
import Dialog from "../../components/dialog/Dialog.vue";
export default {
  components: {
    Dialog,
  },
  data() {
    return {
      tableRowIndex: null,
      tableRowIndexUser: null,
      elementHeight: 0,
      // 不符合项
      tableList: [],
      showDialog: false,
      rowItem: {},
      isAdd: true,
      fallName: "",
      fallremark: "",
      decide: "",
      isUse: "0",
      // 不符合项用户
      tableListUser: [],
      isAddUser: true,
      showDialogUser: false,
      isUseUser: "0",
      falid: null,
    };
  },
  mounted() {
    this.getElementHeight();
    this.getTableList();
    this.getTableListUser();
  },
  methods: {
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById("table").offsetTop + 104);
      });
    },

    // rowClick(row) {
    //   this.tableRowIndex = row.index;
    //   this.rowItem = row;
    // },
    // rowClickUser(row) {
    //   this.tableRowIndexUser = row.index;
    //   this.rowItemUser = row;
    // },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    selectedstyle({ rowIndex }) {
      if (this.tableRowIndex == rowIndex) {
        return {
          "background-color": "#E2E8FA !important",
          "font-weight": "bold !important",
          color: "#409EFF",
        };
      }
      if (this.tableRowIndexUser == rowIndex) {
        return {
          "background-color": "#E2E8FA !important",
          "font-weight": "bold !important",
          color: "#409EFF",
        };
      }
    },
    operation(isAdd, rowItem) {
      this.isAdd = isAdd;
      this.showDialog = true;
      if (!isAdd) {
        this.rowItem = rowItem;
        this.fallName = rowItem.fallName;
        this.fallremark = rowItem.fallremark;
        this.decide = rowItem.decide;
        this.isUse = rowItem.isUse + "";
      } else {
        this.fallName = "";
        this.fallremark = "";
        this.decide = "";
        this.isUse = 0 + "";
      }
    },
    handleClose() {
      this.showDialog = false;
    },
    getTableList() {
      get("/api/Falloptions").then((resp) => {
        if (resp.code == 200) {
          this.tableList = resp.data;
        }
      });
    },
    handleReqData() {
      var http = this.isAdd ? post : put;
      http("/api/Falloptions", {
        id: this.isAdd ? 0 : this.rowItem.id,
        fallName: this.fallName,
        isUse: this.isUse * 1,
        fallremark: this.fallremark,
        decide: this.decide,
      })
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: "success",
              message: this.isAdd ? "新增成功" : "修改成功",
            });
            this.getTableList();
            this.showDialog = false;
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error(this.isAdd ? "新增失败" : "修改失败");
        });
    },
    deleteItem(row) {
      this.$confirm("您确认要删除" + row.fallName + "的这个不符合项？").then(
        () => {
          deleteMethod("/api/Falloptions/" + row.id)
            .then((resp) => {
              if (resp.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功",
                });
                this.getTableList();
              } else {
                this.$message.error(resp.message);
              }
            })
            .catch(() => {
              this.$message.error("删除失败");
            });
        }
      );
    },
    // 不符合项用户
    getTableListUser() {
      get(
        "/api/Falloptionbyuser?TenantId=" +
          JSON.parse(sessionStorage.getItem("tenantId"))
      ).then((resp) => {
        if (resp.code == 200) {
          this.tableListUser = resp.data;
        }
      });
    },
    handleCloseUser() {
      this.showDialogUser = false;
    },
    operationUser(isAddUser, rowItemUser) {
      this.isAddUser = isAddUser;
      this.showDialogUser = true;
      if (this.isAddUser) {
        this.falid = null;
        this.isUseUser = "0";
      } else {
        this.rowItemUser = rowItemUser;
        this.falid = rowItemUser.falid;
        this.isUseUser = rowItemUser.isUse + "";
      }
    },
    handleReqDataUser() {
      var tenantId = JSON.parse(sessionStorage.getItem("tenantId"));
      var http = this.isAddUser ? post : put;
      http("/api/Falloptionbyuser?TenantId=" + tenantId, {
        id: this.isAddUser ? 0 : this.rowItemUser.id,
        tenantId: tenantId,
        falid: this.falid,
        isUse: this.isUseUser * 1,
        saveTime: new Date().toISOString(),
        saveUserId: this.$store.state.userInfo.id,
      })
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: "success",
              message: this.isAdd ? "新增成功" : "修改成功",
            });
            this.getTableListUser();
            this.showDialogUser = false;
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error(this.isAdd ? "新增失败" : "修改失败");
        });
    },
    deleteItemUser(row) {
      this.$confirm("您确认要删除这个" + row.fallName + "的用户？").then(() => {
        deleteMethod("/api/Falloptionbyuser?Id=" + row.id)
          .then((resp) => {
            if (resp.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功",
              });
              this.getTableListUser();
            } else {
              this.$message.error(resp.message);
            }
          })
          .catch(() => {
            this.$message.error("删除失败");
          });
      });
    },
  },
};
</script>

<style lang="less" scoped>
._SelectHeader {
  height: 30px;
  background: #4b85fe;
  padding: 5px 10px;
  color: white;
  font-weight: bold;
}
.towtable {
  display: flex;
  > div:nth-of-type(1) {
    width: 60%;
  }
  > div:nth-of-type(2) {
    width: 35%;
    margin-left: 20px;
  }
}
.nofall {
  /deep/ .el-dialog__body {
    line-height: 30px !important;
  }
}
</style>